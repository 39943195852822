/**
 * Atc2Json js library
 * @module universalFormatter.js
 * @version 1.0.0
 * @author Shriram Santhanam
 * @param {Object} data - The atc file data as a Uint8Array
 * @param {String} type - The atc file data as a Uint8Array
 *
 * @return {Object} {original, filtered, annotations}
 *
 */

import { defaultFilterECG } from "./ecgFilter";
import calculateLeads from "./leadCalc";
import RhythmMorphologyGen from "./RhythmMorphology";

export default function FnWithPerformance (data, type) {
    const start = performance.now()
    const returnVal = universalFormatter(data, type)
    const end = performance.now()
    window.printPerformance("Formatting the ECG took:",`${(end-start).toFixed(2)}ms`);
    return returnVal
}

function universalFormatter(data, type) {
    let original = {
        frequency: 0,
        mainsFrequency: 0,
        gain: 0,
        duration: 0,
        samples: {},
        info: {},
    }

    let filtered = {
        frequency: 0,
        mainsFrequency: 0,
        gain: 0,
        duration: 0,
        samples: {},
        filters: {
            adaptive: false,
            ButterworthLowPass: 0,
            ButterworthHighPass: 0,
        },
        info: {},
    }

    let annotations = {
        beats: [], // [offset, beatType]
        intervals: [],
        doctorNote: "",
        global: [],
        labels: {
            beats: [{name: "Normal", color: "#00ff00", slug: "n"}],
            intervals: [{id: 0 , name: "Noise", color: "#00ff00", slug: "z"}],
        },
        algDet: [],
        algStatement: { morphology: [], rhythm:[], type: ""},
        overreadMode: null,
        NeedFollowup: null,
        originalDx: null,
        confirmedDx: null,
        audit: [],
    }

    let error = null

	switch (type) {
		case "atc":
            // Check if the JSON is a valid ATC JSON
            if (!data.hasOwnProperty("ecg")) {
                return {
                    original,
                    filtered,
                    annotations,
                    error: "Uploaded ATC Not Valid",
                }
            }
            // Check if the ATC JSON is 6L or 1L
            let ecgLeads = {}
            if (data.hasOwnProperty("ecg2")) {
                // 6L
                ecgLeads = {
                    I: data.ecg.Data,
                    II: data.ecg2.Data,
                    III: data.ecg3.Data,
                    aVR: data.ecg4.Data,
                    aVL: data.ecg5.Data,
                    aVF: data.ecg6.Data,
                }
            } else {
                // 1L
                ecgLeads = {
                    I: data.ecg.Data,
                }
            }
            // Read ECG Graph meta from ATC JSON
            original.frequency = data.fmt.Fs
            original.mainsFrequency = data.fmt.Flags[1] === 1 ? 60 : 50
            original.gain = 1e6 / data.fmt.AmpRes_nV
            original.duration = data.ecg.Data.length / data.fmt.Fs
            original.info = data.info

            filtered.frequency = original.frequency
            filtered.mainsFrequency = original.mainsFrequency
            filtered.gain = original.gain
            filtered.duration = original.duration
            filtered.info = original.info

            // Check if the ATC JSON is Raw ECG or Enhanced ECG
            if(!data.fmt.Flags[6]) {
                // Raw ECG
                original.samples = ecgLeads;
                filtered.samples = defaultFilterECG(original.frequency, original.mainsFrequency, original.samples);
            } else {
                // Enhanced ECG
                filtered.samples = ecgLeads;
            }
            // Set default filters
            filtered.filters.ButterworthHighPass = 0.5
            filtered.filters.ButterworthLowPass = 40
            filtered.filters.adaptive = true

            // Check if the ATC JSON has annotations
            if (data.hasOwnProperty("ann")) {
                annotations.labels.beats = [
                    {name: "Normal", color: "#6CCA6E", slug: "n"},
                    {name: "Unknown", color: "#797979", slug: "u"},
                    {name: "Ventricular", color: "#FF6961", slug: "v"},
                    {name: "DEFAULT_LEFT_CLICK", color: "#8c8c8c", slug: "ddf"},
                ]
                data.ann.Data.map(beat => {
                    let type = "u"
                    switch (beat[1]) {
                        case 0:
                            type = "u"
                            break;
                        case 1:
                            type = "n"
                            break;
                        case 2:
                            type = "v"
                            break;
                        default:
                            type = "u"
                            break;
                    }
                    annotations.beats.push({i: beat[0], t:type})
                })
            }

            annotations.audit.push({time: new Date().toISOString(),person: "system",message: "File opened"})

            // CHECK IF ITS A TURK JSON 
            if (data.hasOwnProperty("annotations") ) {
                if (data.annotations.hasOwnProperty("beatsV2"))
                    annotations.beats = data.annotations.beatsV2
                if (data.annotations.hasOwnProperty("intervalsV2"))
                    annotations.intervals = data.annotations.intervalsV2
                annotations.audit = data.annotator


                if (data.annotations.hasOwnProperty("global"))
                    annotations.global = data.annotations.global
                if (data.annotations.hasOwnProperty("labels")) {
                    if (data.annotations.labels.hasOwnProperty("beats")) {
                        annotations.labels.beats = data.annotations.labels.beats
                    }
                    if (data.annotations.labels.hasOwnProperty("intervals")) {
                        annotations.labels.intervals = data.annotations.labels.intervals
                    }
                }
                if (data.annotations.hasOwnProperty("doctorNote")) {
                    annotations.doctorNote = data.annotations.doctorNote
                }
            }
            return {
                original,
                filtered,
                annotations,
                error,
            }
		case "json":
            // Check if the JSON is a valid 12L ECG
            if (!data.hasOwnProperty("rawEcg")) {
                return {
                    original,
                    filtered,
                    annotations,
                    error: "Uploaded JSON is not a valid 12L ECG",
                }
            }
            // Read ECG Graph meta from JSON
            original.frequency = data.rawEcg.samplesPerSecond
            original.mainsFrequency = data.mainsFreq
            original.gain = 1000
            
            // Check if the JSON is Raw ECG or Enhanced ECG and compute All Leads
            if (data.hasOwnProperty('rawEcg')) {
                original.samples = data.rawEcg.ecgSamples;
                let ecgLeads = {}
                ecgLeads = defaultFilterECG(original.frequency, original.mainsFrequency, original.samples);
                ecgLeads = calculateLeads(ecgLeads);
                filtered.samples = ecgLeads;
            } else if (data.hasOwnProperty('enhEcg')) {
                original.samples = data.rawEcg.ecgSamples;
                filtered.frequency = data.enhEcg.samplesPerSecond;
                let ecgLeads = {}
                ecgLeads = data.enhEcg.ecgSamples;
                ecgLeads = calculateLeads(ecgLeads);
                filtered.samples = ecgLeads;
            } else {
                return {
                    original,
                    filtered,
                    annotations,
                    error: "Uploaded JSON is not a valid 12L ECG",
                }
            }

            // Set default filters
            filtered.filters.ButterworthHighPass = 0.5
            filtered.filters.ButterworthLowPass = 40
            filtered.filters.adaptive = true

            // Set filtered ECG Graph meta from JSON
            filtered.frequency = filtered.frequency ? filtered.frequency : original.frequency
            filtered.gain = original.gain
            filtered.mainsFrequency = original.mainsFrequency
            original.duration = data.rawEcg.ecgSamples.I.length / data.rawEcg.samplesPerSecond
            filtered.duration = original.duration


            // Read ECG Info from JSON
            original.info = {
                ...data.ecgDeviceInfo,
                ...data.platformInfo,
                mode: data.recordMode,
            }
            filtered.info = original.info

            // Check if the JSON has annotations
            annotations.labels.beats = [
                {name: "Normal", color: "#6CCA6E", slug: "n"},
                {name: "Unknown", color: "#797979", slug: "u"},
                {name: "Ventricular", color: "#FF6961", slug: "v"},
                {name: "DEFAULT_LEFT_CLICK", color: "#8c8c8c", slug: "ddf"},
            ]
            if (
                data.hasOwnProperty("kaiAnalysis") &&
                data.kaiAnalysis.beats &&
                data.kaiAnalysis.beats.length > 0
            ) {
                const beats = data.kaiAnalysis.beats
                annotations.beats = beats.map((beat) => {
                    return {i: beat.offset, t: "n"}
                })
            }
            annotations.audit.push({time: new Date().toISOString(),person: "system",message: "File opened"})

            // Rhythm Morphology Generation
            if (
                data.hasOwnProperty("kai12Analysis") &&
                data.kai12Analysis.hasOwnProperty("morphologyHighSpec") &&
                data.kai12Analysis.hasOwnProperty("morphologyHighSens") &&
                data.kai12Analysis.hasOwnProperty("rhythmHighSpec") &&
                data.kai12Analysis.hasOwnProperty("rhythmHighSens")
            ) {
                annotations.algStatement = {...annotations.algStatement, ...RhythmMorphologyGen(data.kai12Analysis)}
            }
            
            // CHECK IF ITS A TURK JSON 
            if (data.hasOwnProperty("annotations") ) {
                if (data.annotations.hasOwnProperty("beatsV2"))
                    annotations.beats = data.annotations.beatsV2
                if (data.annotations.hasOwnProperty("intervalsV2"))
                    annotations.intervals = data.annotations.intervalsV2
                annotations.audit = data.annotator


                if (data.annotations.hasOwnProperty("global"))
                    annotations.global = data.annotations.global
                if (data.annotations.hasOwnProperty("labels")) {
                    if (data.annotations.labels.hasOwnProperty("beats")) {
                        annotations.labels.beats = data.annotations.labels.beats
                    }
                    if (data.annotations.labels.hasOwnProperty("intervals")) {
                        annotations.labels.intervals = data.annotations.labels.intervals
                    }
                }
                if (data.annotations.hasOwnProperty("doctorNote")) {
                    annotations.doctorNote = data.annotations.doctorNote
                }

                // Handling the overread JSON files
                if (data.annotations.hasOwnProperty("overreadMode") && data.annotations.overreadMode === true) {
                    annotations.overreadMode = data.annotations.overreadMode
                    annotations.NeedFollowup = data.annotations.NeedFollowup
                    annotations.comment = data.annotations.comment
                    annotations.originalDx = data.annotations.originalDx
                    annotations.confirmedDx = data.annotations.confirmedDx
                }
            }

            return {
                original,
                filtered,
                annotations,
                error,
            }
		case "turk":
            // Check if the JSON is a valid 12L ECG
            if (!data.hasOwnProperty("samples")) {
                return {
                    original,
                    filtered,
                    annotations,
                    error: "Uploaded JSON is not a valid 12L ECG",
                }
            }
            let ecgTurkLeads = {}
            ecgTurkLeads.I = data.samples;
            if (data.hasOwnProperty("extraLeads")) {
                data.extraLeads.map((lead, index) => {
                    const leadName = lead.title.split(" ")[1];
                    ecgTurkLeads[leadName] = lead.samples;
                });
            }
            if (ecgTurkLeads.I && ecgTurkLeads.II) {
                // ecgTurkLeads = defaultFilterECG(data.frequency, 50, ecgTurkLeads);
                ecgTurkLeads = calculateLeads(ecgTurkLeads);
                filtered.filters.ButterworthHighPass = 0.5
                filtered.filters.ButterworthLowPass = 40
                filtered.filters.adaptive = true
            }

            original.frequency = data.frequency
            original.mainsFrequency = 50
            original.gain = data.gain
            original.duration = data.duration/data.frequency
            original.info = {}

            filtered.frequency = data.frequency
            filtered.mainsFrequency = 50
            filtered.gain = data.gain
            filtered.duration = data.duration/data.frequency
            filtered.samples = ecgTurkLeads
            filtered.info = {}

            // Read turk annotation
            
            if (data.hasOwnProperty("algorithmLabels")) 
                annotations.algDet = data.algorithmLabels

            if (data.hasOwnProperty("annotator")) 
                annotations.audit = data.annotator

            if (data.hasOwnProperty("annotations") && data.annotations.hasOwnProperty("global")) 
                annotations.global = data.annotations.global

            let intervalLabels = {}
            let intervalLabelsArray = []
            // Extract Labels from data
            if (data.hasOwnProperty("intervalLabels")) {
                data.intervalLabels.map((label, index) => {
                    intervalLabels[label] = index
                    intervalLabelsArray.push({id: index, name: label, color: "#00ff00", slug: label[0].toLowerCase()})
                })
                annotations.labels.intervals = intervalLabelsArray
            }

            let beatLabels = {}
            let beatLabelsArray = []
            // Extract Labels from data
            if (data.hasOwnProperty("legend") && data.legend.hasOwnProperty("beats")) {
                // data.legend.beats.map((beat, index) => {
                //     beatLabels[beat.label] = index
                //     beatLabelsArray.push({id: index, name: beat.name, color: beat.color, slug: beat.label})
                // })
                // annotations.labels.beats = beatLabelsArray

                annotations.labels.beats = [
                    {name: "DEFAULT_LEFT_CLICK", color: "#8c8c8c", slug: "ddf"},
                ]
                data.legend.beats.map((beatLegends, index) => {
                    annotations.labels.beats.push ({name: beatLegends.name, color: beatLegends.color, slug: beatLegends.label})
                })
                if(data.legend.beats.length === 0)
                   
                annotations.labels.beats = [
                    {name: "Normal", color: "#6CCA6E", slug: "n"},
                    {name: "Unknown", color: "#797979", slug: "u"},
                    {name: "Ventricular", color: "#FF6961", slug: "v"},
                    {name: "DEFAULT_LEFT_CLICK", color: "#8c8c8c", slug: "ddf"},
                ]
            }

            if (data.hasOwnProperty("annotations") && data.annotations.hasOwnProperty("beatLabels")) {
                if ( data.annotations.hasOwnProperty("beatsV2") ) {
                    annotations.beats = data.annotations.beatsV2
                } else {
                    let beatFinalArray = []
                    data.annotations.beatLabels.map((beat, index) => {
                        beatFinalArray.push({i: beat.sample, t:beat.label})
                    })
                    // data.annotations.beatLabels.map((beat, index) => {
                    //     beatFinalArray.push([beat.sample, beatLabels[beat.label]])
                    // });
                    annotations.beats = beatFinalArray
                }
            }

            // TODO: Port Turk Intervals to Annotator
            if (data.hasOwnProperty("annotations") && data.annotations.hasOwnProperty("intervalsV2")) {
                annotations.intervals = data.annotations.beatsV2
            }
            // if (data.hasOwnProperty("annotations") && data.annotations.hasOwnProperty("intervals")) {
            //     // To Do : Convert to [offset, beatType]
            //     annotations.intervals = data.annotations.intervals
            // }
            // "intervals": [
            //     {
            //         "end": 2007,
            //         "label": "Noisy",
            //         "start": 1142
            //     }
            // ],

			return {
				original,
				filtered,
				annotations,
				error,
			}
		default:
			return {
				original,
				filtered,
				annotations,
				error: "Universal Formatter: Invalid type",
			}
	}
}

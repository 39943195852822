/**
 * Library to filter ECG Signal. 
 * Implements: 
 *      - Adaptive Filter, 
 *      - Butterworth Highpass Filter, 
 *      - Butterworth Lowpass Filter
 * @module ecgFilter.js
 * @version 1.0.0
 * @author Kim Barnett
*/

const FILTER_TYPE = Object.freeze({
    LOW_PASS: 'lowpass',
    HIGH_PASS: 'highpass',
});

/** Generates a Flat Top window for minimizing spectral leakage. */
const generateFlatTopWindow = (numPoints) => {
    // Coefficients of the Flat Top window
    const a0 = 0.21557895;
    const a1 = 0.41663158;
    const a2 = 0.277263158;
    const a3 = 0.083578947;
    const a4 = 0.006947368;

    return Array.from({ length: numPoints }, (_, n) =>
        a0 -
        a1 * Math.cos((1 * 2 * Math.PI * n) / (numPoints - 1)) +
        a2 * Math.cos((2 * 2 * Math.PI * n) / (numPoints - 1)) -
        a3 * Math.cos((3 * 2 * Math.PI * n) / (numPoints - 1)) +
        a4 * Math.cos((4 * 2 * Math.PI * n) / (numPoints - 1))
    );
};

/**
 * Goertzel Algorithm calculates the magnitude and phase of a frequency component.
 *
 *  This implementation applies a Flat Top window to the signal samples to minimize spectral leakage before calculating
 *  the frequency component's magnitude and phase. It then corrects the phase based on the position within the sample set.
 */
const goertzel = (samples, samplingRate, targetFrequency) => {
    const numSamples = samples.length;
    const k = Math.round(0.5 + (numSamples * targetFrequency) / samplingRate);
    const omega = (2 * Math.PI * k) / numSamples;
    const coeff = 2.0 * Math.cos(omega);

    let q0 = 0.0;
    let q1 = 0.0;
    let q2 = 0.0;

    // Apply the Flat Top window to the samples and remove the DC offset
    // DC offset is removed to improve Goertzel algorithm accuracy by reducing spectral leakage,
    // even though target frequency (e.g., 50/60Hz) is well-separated from DC
    const dcOffset = samples.reduce((sum, value) => sum + value, 0) / samples.length;
    const window = generateFlatTopWindow(numSamples);
    const windowedSamples = samples.map((sample, index) => (sample - dcOffset) * window[index]);

    for (const sample of windowedSamples) {
        q0 = coeff * q1 - q2 + sample;
        q2 = q1;
        q1 = q0;
    }

    const real = q1 - q2 * Math.cos(omega);
    const imag = q2 * Math.sin(omega);
    const magnitude = (Math.sqrt(real ** 2 + imag ** 2) / window.reduce((sum, value) => sum + value, 0)) * 2;

    const correctionReal = Math.cos(-omega * (numSamples - 1));
    const correctionImag = Math.sin(-omega * (numSamples - 1));
    const correctedReal = real * correctionReal - imag * correctionImag;
    const correctedImag = real * correctionImag + imag * correctionReal;
    const correctedPhase = Math.atan2(correctedImag, correctedReal) + Math.PI / 2.0;

    return [magnitude, correctedPhase];
};

/** Computes Butterworth filter coefficients for a given order and type. */
const calculateCoefficients = (cutoffFrequency, samplingRate, order, filterType) => {
    if (order > 2) {
        throw new Error('Unsupported filter order. Only first and second order are supported.');
    }
    const normalizedFreq = (2 * Math.PI * cutoffFrequency) / samplingRate;

    if (order === 1) {
        const tanValue = Math.tan(normalizedFreq / 2);
        const b0 = filterType === FILTER_TYPE.LOW_PASS ? (2 * tanValue) / (2 + 2 * tanValue) : 2 / (2 + 2 * tanValue);
        const b1 = filterType === FILTER_TYPE.LOW_PASS ? b0 : -b0;
        const a1 = (2 * tanValue - 2) / (2 + 2 * tanValue);

        return { bCoeffs: [b0, b1, 0], aCoeffs: [1, a1, 0] };
    }

    if (order === 2) {
        const Q = 1 / Math.sqrt(2); // Quality factor for 2nd order Butterworth filter
        const cosValue = Math.cos(normalizedFreq);
        const sinValue = Math.sin(normalizedFreq);
        const alpha = sinValue / (2 * Q);

        const b0 = filterType === FILTER_TYPE.LOW_PASS ? (1 - cosValue) / 2 : (1 + cosValue) / 2;
        const b1 = filterType === FILTER_TYPE.LOW_PASS ? 1 - cosValue : -(1 + cosValue);
        const b2 = b0;
        const a0 = 1 + alpha;
        const a1 = -2 * cosValue;
        const a2 = 1 - alpha;

        // Normalize coefficients to ensure a0 = 1
        return {
            bCoeffs: [b0 / a0, b1 / a0, b2 / a0],
            aCoeffs: [1, a1 / a0, a2 / a0],
        };
    }
};

/** Applies a single-pass digital filter to a signal. */
const lfilter = (samples, bCoeffs, aCoeffs, filterType) => {
    const [b0, b1, b2] = bCoeffs;
    const [_, a1, a2] = aCoeffs; // Ignore aCoeffs[0] since it’s assumed to be 1

    // Extend the length of the input signal by 2 using the last sample
    const extendedSignal = [
        ...samples,
        samples[samples.length - 1],
        samples[samples.length - 1],
    ];

    const output = new Array(samples.length).fill(0);

    // Initialize state of filter to prevent transients at the start and end of the filtered signal
    const steadyStateValue = filterType === FILTER_TYPE.LOW_PASS ? samples[0] : 0;
    let d1 = steadyStateValue;
    let d2 = steadyStateValue;

    for (let i = 0; i < samples.length; i++) {
        output[i] =
            b0 * extendedSignal[i + 2] +
            b1 * extendedSignal[i + 1] +
            b2 * extendedSignal[i] -
            a1 * d1 -
            a2 * d2;

        d2 = d1;
        d1 = output[i];
    }

    return output;
};

/**
 * Applies zero-phase forward and reverse digital filtering.
 *
 * This implementation is similar to Pythons filtfilt method but simpler and more effective filter
 * initialization for our use case
 */
const filtfilt = (samples, bCoeffs, aCoeffs, samplingRate, filterType) => {
    const padLength = 3 * Math.max(bCoeffs.length, aCoeffs.length);

    const startMean = samples
        .slice(0, Math.floor(samplingRate / 5))
        .reduce((sum, value) => sum + value, 0) / Math.floor(samplingRate / 5);

    const endMean = samples
        .slice(-Math.floor(samplingRate / 5))
        .reduce((sum, value) => sum + value, 0) / Math.floor(samplingRate / 5);

    const paddedSignal = [
        ...Array(padLength).fill(startMean),
        ...samples,
        ...Array(padLength).fill(endMean),
    ];

    const forwardFiltered = lfilter(paddedSignal, bCoeffs, aCoeffs, filterType);
    const reverseFiltered = lfilter(forwardFiltered.reverse(), bCoeffs, aCoeffs, filterType);

    return reverseFiltered.reverse().slice(padLength, -padLength);
};

/**
 * Adaptive Filter for Mains Frequency Noise Removal.
 *
 * This function removes mains frequency noise from ECG data using an adaptive filter. It is specifically designed for
 * post-processing ECG signals and uses the Goertzel algorithm to calculate the noise magnitude and phase at the start
 * of the ECG, initializing the filter so there is no initial adaptive phase.
 * @param {number} samplingRate - Sampling rate of the signal
 * @param {number} mainsFrequency - Mains frequency (50/60 Hz)
 * @param {number[]} samples - Input signal samples
 * @returns {number[]} Filtered samples
 */
export const adaptiveFilter = (samplingRate, mainsFrequency, samples) => {
    const normalizedFreq = mainsFrequency / samplingRate;
    const qCoeff = 2 * Math.cos(2 * Math.PI * normalizedFreq);
    const slopeThreshold = (1200.0 * 500.0) / samplingRate;
    const stepSize = (4688 * normalizedFreq) / samplingRate;

    let h1 = 0.0, h2 = 0.0, y1 = 0.0, d1 = 0.0, x1 = 0.0;

    const filterSample = (value) => {
        const h00 = qCoeff * h1;
        let h0 = h00 - h2;
        const y0 = value - h0;
        const d0 = y0 - y1;
        d1 = d0;

        if (Math.abs(value - x1) < slopeThreshold) {
            h0 += y0 > y1 ? stepSize : -stepSize;
        }

        h2 = h1;
        h1 = h0;
        y1 = y0;
        x1 = value;

        return y0;
    };

    // Use initial 1 second of data to calculate mains amplitude and phase
    const initSamples = Math.floor(samplingRate);
    if (samples.length > initSamples) {
        const [amplitude, phase] = goertzel(samples.slice(0, initSamples), samplingRate, mainsFrequency);
        h1 = amplitude * Math.sin((2 * Math.PI * mainsFrequency * -1) / samplingRate + phase);
        h2 = amplitude * Math.sin((2 * Math.PI * mainsFrequency * -2) / samplingRate + phase);
    }

    return samples.map((sample) => filterSample(sample));
};

/**
 * Low-pass Butterworth filter
 * @param {number} cutoffFrequency - Cutoff frequency
 * @param {number} samplingRate - Sampling rate
 * @param {number} order - Filter order
 * @param {number[]} samples - Input samples
 * @returns {number[]} Filtered samples
 */
export const lowpassButterworthFilter = (cutoffFrequency, samplingRate, order, samples) => {
    const { bCoeffs, aCoeffs } = calculateCoefficients(
        cutoffFrequency,
        samplingRate,
        order,
        FILTER_TYPE.LOW_PASS
    );
    return filtfilt(samples, bCoeffs, aCoeffs, samplingRate, FILTER_TYPE.LOW_PASS);
};

/**
 * High-pass Butterworth filter
 * @param {number} cutoffFrequency - Cutoff frequency
 * @param {number} samplingRate - Sampling rate
 * @param {number} order - Filter order
 * @param {number[]} samples - Input samples
 * @returns {number[]} Filtered samples
 */
export const highpassButterworthFilter = (cutoffFrequency, samplingRate, order, samples) => {
    const { bCoeffs, aCoeffs } = calculateCoefficients(
        cutoffFrequency,
        samplingRate,
        order,
        FILTER_TYPE.HIGH_PASS
    );
    return filtfilt(samples, bCoeffs, aCoeffs, samplingRate, FILTER_TYPE.HIGH_PASS);
};

/**
 * Default ECG filtering pipeline
 * @param {number} samplingRate - Sampling rate
 * @param {number} mainsFrequency - Mains frequency
 * @param {Object.<string, number[]>} ecgLeads - ECG leads with sample data
 * @returns {Object.<string, number[]>} Filtered ECG leads
 */
export const defaultFilterECG = (samplingRate, mainsFrequency, ecgLeads) => {
    const start = performance.now();
    const filteredLeads = {};

    for (const [lead, samples] of Object.entries(ecgLeads)) {
        let filteredSamples = adaptiveFilter(samplingRate, mainsFrequency, samples);
        filteredSamples = lowpassButterworthFilter(40, samplingRate, 2, filteredSamples);
        filteredSamples = highpassButterworthFilter(0.5, samplingRate, 2, filteredSamples);
        filteredLeads[lead] = filteredSamples;
    }

    const end = performance.now();
    window.printPerformance("Default ECG Filters took:",`${(end-start).toFixed(2)}ms`);
    return filteredLeads;
};
